import React, { Component } from "react"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CareersForm from "./CareersForm"
import CareerOption from "./CareerOption"

export default class Careers extends Component {
  state = {
    expanded: null,
    roleSelected: false,
    sent: false
  }

  handleChange = panel => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false
    })
  }

  manageSelected = selected => {
    this.props.careerActions.setSelected(selected)
  }

  renderJobOptions = () => {
    const { expanded } = this.state
    return (
      <div style={{ margin: "0 auto 120px auto", maxWidth: "500px" }}>
        <ExpansionPanel
          expanded={expanded === "panel1"}
          onChange={this.handleChange("panel1")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className="expandHeading">Front-End</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="expandDetail">
            <CareerOption
              jobRole="Full-Stack JavaScript Developer Jobs"
              topRow={true}
              {...this.props}
            />
            <CareerOption jobRole="React Developer Jobs" {...this.props} />
            <CareerOption jobRole="AngularJS Developer Jobs" {...this.props} />
            <CareerOption jobRole="Vue.js Developer Jobs" {...this.props} />
            <CareerOption jobRole="Frontend Developer Jobs" {...this.props} />
            <CareerOption jobRole="WordPress Developer Jobs" {...this.props} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel2"}
          onChange={this.handleChange("panel2")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className="expandHeading">Back-End</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="expandDetail">
            <CareerOption
              jobRole="Express.JS Developer Jobs"
              topRow={true}
              {...this.props}
            />
            <CareerOption
              jobRole="JavaScript Back-End Developer Jobs"
              {...this.props}
            />
            <CareerOption jobRole="Node.js Developer Jobs" {...this.props} />
            <CareerOption
              jobRole="Ruby on Rails Developer Jobs"
              {...this.props}
            />
            <CareerOption jobRole="PHP Developer Jobs" {...this.props} />
            <CareerOption jobRole="SharePoint Developer Jobs" {...this.props} />
            <CareerOption jobRole="Salesforce Developer Jobs" {...this.props} />
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          expanded={expanded === "panel3"}
          onChange={this.handleChange("panel3")}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className="expandHeading">Project Management</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="expandDetail">
            <CareerOption
              jobRole="Project Manager (Agile/Scrum)"
              topRow={true}
              {...this.props}
            />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    )
  }

  renderCareersForm = () => {
    return (
      <div>
        <CareersForm {...this.props} />
      </div>
    )
  }

  manageRender = () => {
    if (this.props.careers.selectedOption === "") {
      return this.renderJobOptions()
    } else {
      return this.renderCareersForm()
    }
  }

  render = () => {
    return (
      <div style={{ backgroundColor: "#fff", padding: "60px 0 0 0" }}>
        <div
          className="sectionBg"
          style={{
            marginBottom: "20px",
            paddingBottom: "20px"
          }}
        >
          <div className="sectionTitle color1">
            REMOTE WORK FOR THE MOTIVATED
          </div>
          <div className="sectionSubTitle">
            Great Work Starts With Great People
          </div>

          <div className="divideSM" style={{ margin: "0 0 20px 0" }} />

          <div className="sectionText">
            <span>
              We're hiring! We don't want average, we don't even want good. We
              want "all-stars" who are passionate about what they do.
            </span>
          </div>

          <div
            style={{ margin: "60px 0 0 0" }}
            className="sectionTitle3 color1 centerH"
          >
            Jobs That Unleash Your Potential
          </div>
        </div>

        {/* HERE */}
        {this.manageRender()}
        {/* THERE*/}
      </div>
    )
  }
}
