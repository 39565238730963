import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import { logger } from "redux-logger"
import promise from "redux-promise-middleware"
import reducers from "../reducers"

// if upgrade redux-romise-middleware you will need to change promise() to just promise in the line of code below
const middleware = applyMiddleware(promise(), thunk, logger)

export default createStore(reducers, middleware)
