/*
import {
  USER_NAME,
} from '../constants/constants'
*/

const initialState = {
  userName: ""
}

export default function(state = initialState, action) {
  switch (action.type) {
    case "UPDATE_USER_NAME": {
      return {
        ...state,
        userName: ""
      }
    }

    default: {
      return state
    }
  }
}
