import React, { Component } from "react"

export default class WhoWeAre extends Component {
  render = () => (
    <div
      className="aboutBg"
      style={{
        padding: "20px 40px 20px 40px"
      }}
    >
      <div className="sectionBg">
        <div className="sectionTitle color1">Who We Are</div>
        <div className="sectionSubTitle">
          Developers. Designers. Marketers. Creators.
        </div>
        <div className="sectionText">
          <span>
            Companies rely on Cywise to provide a
            complete range of digital services including web/mobile application
            design and development.
          </span>

          <span>
            Our client base is comprised of companies from a broad spectrum of
            industries. Whether you are a solopreneur or Fortune 500 we are here
            to help you.
          </span>

          <span>
            Our biggest asset is our people – knowledgeable designer,
            developers, engineers and marketers.
          </span>

          <span>
            With a unique combination of strategic thinking, creativity and
            technological expertise, we provide a powerful blend of talented
            professionals and digital solutions.
          </span>
        </div>
      </div>
    </div>
  )
}
