import React, { Component } from "react"
import { sendNewsletterForm } from "../../webApi/awsApi"
import { library } from "../../utils/validators"

export default class Ideas extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      email: "",
      emailError: ""
    }

    this.state = this.initialState
  }

  validate = () => {
    let { email } = this.state //PHONE WAS REMOVED AS ITS NO LONGER REQUIRED
    let errorCount = 0

    if (library.isBlank(email)) {
      this.setState({
        emailError: "Please provide a valid email so we can respond to you."
      })

      errorCount = errorCount + 1
    } else {
      this.setState({ emailError: "" })

      if (!library.isEmail(email)) {
        this.setState({ emailError: "Please enter a valid email address." })

        errorCount = errorCount + 1
      } else {
        this.setState({ emailError: "" })
      }
    }
    console.log("state: ", this.state)
    return errorCount
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleSubmit = () => {
    let hasErrors = this.validate()

    if (hasErrors) {
      return
    }

    const { email } = this.state

    const data = {
      email: email
    }

    sendNewsletterForm(data) //api
    this.setState(this.initialState)
    this.props.sharedActions.setNewsletterSubmitted(true)
  }

  renderForm = () => {
    return (
      <div className="ideasBox fill2">
        <div className="ideasTitle ">
          Quarterly Growth Strategy Ideas directly in your inbox
        </div>
        <div className="ideasAction">
          <div className="ideasEmailBox centerChildren">
            <input
              className="ideasEmailTB"
              type="text"
              onChange={this.handleChange("email")}
            />
          </div>
          <div
            className="ideasSubscribeBtn"
            tabIndex="1"
            onClick={() => {
              this.handleSubmit()
            }}
          >
            <div className="ideasSubscribeBtnText">SUBSCRIBE</div>
          </div>
        </div>
      </div>
    )
  }

  renderThankYou = () => {
    return (
      <div className="ideasBox fill2">
        <div className="ideasTitle ">
          Thank you for joining! You'll recieve the next release at the address
          provided.
        </div>
      </div>
    )
  }

  manageRender = () => {
    if (this.props.shared.newsletterSubmitted) {
      return this.renderThankYou()
    } else {
      return this.renderForm()
    }
  }

  render = () => <div>{this.manageRender()}</div>
}
