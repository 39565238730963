import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { library } from "../../utils/validators"
import { sendContactForm } from "../../webApi/awsApi"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.initialState = {
      name: "",
      nameError: "",
      email: "",
      emailError: "",
      phone: "",
      phoneError: "",
      message: "",
      messageError: "",
      formError: ""
    }

    this.state = this.initialState
  }

  validate = () => {
    let { name, email, message } = this.state //PHONE WAS REMOVED AS ITS NO LONGER REQUIRED

    let errorCount = 0

    if (library.isBlank(name)) {
      this.setState({
        nameError:
          "Oops, you forgot to tell us your name. What should we call you?"
      })

      errorCount = errorCount + 1
    } else {
      this.setState({ nameError: "" })
    }

    if (library.isBlank(email)) {
      this.setState({
        emailError: "Please provide a valid email so we can respond to you."
      })

      errorCount = errorCount + 1
    } else {
      this.setState({ emailError: "" })

      if (!library.isEmail(email)) {
        this.setState({ emailError: "Please enter a valid email address." })

        errorCount = errorCount + 1
      } else {
        this.setState({ emailError: "" })
      }
    }

    if (library.isBlank(message)) {
      this.setState({ messageError: "Please provide a message." })
      errorCount = errorCount + 1
    } else {
      this.setState({ messageError: "" })
    }

    return errorCount
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleContactForm = () => {
    let hasErrors = this.validate()

    if (hasErrors) {
      return
    }

    const { name, email, phone, message } = this.state

    const data = {
      name: name,
      email: email,
      phone: phone,
      content: message
    }

    sendContactForm(data) //api
    this.setState(this.initialState)
    this.props.contactActions.setMessageSent(true)
  }

  renderContactForm = () => {
    return (
      <div>
        <div className="centerH marginBot30">
          The Cywise team is here to discuss your ideas and goals. Get in touch
          with us by completing this form.
        </div>
        <div
          className="contactFormHolder"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <form noValidate autoComplete="off">
            <div>
              <TextField
                id="contact-name"
                required
                fullWidth
                label="NAME"
                //className="textField"
                value={this.state.name}
                onChange={this.handleChange("name")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.nameError}</div>
            </div>

            <div>
              <TextField
                id="contact-email"
                required
                fullWidth
                label="EMAIL ADDRESS"
                // className={classes.textField}
                value={this.state.email}
                onChange={this.handleChange("email")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.emailError}</div>
            </div>

            <div>
              <TextField
                id="contact-phone"
                fullWidth
                label="PHONE #"
                // className={classes.textField}
                value={this.state.phone}
                onChange={this.handleChange("phone")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.phoneError}</div>
            </div>

            <div>
              <TextField
                id="contact-message"
                required
                label="HOW CAN WE HELP YOU?"
                // className={classes.textField}
                value={this.state.message}
                multiline
                fullWidth
                rows="4"
                rowsMax="4"
                onChange={this.handleChange("message")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.messageError}</div>
            </div>

            <div style={{ margin: "20px auto 0 auto", textAlign: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.handleContactForm()
                }}
                className="primaryBtn"
                style={{ margin: "0 auto 0 auto" }}
              >
                SEND
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  resetContactForm = () => {
    this.props.contactActions.setMessageSent(false)
  }

  renderThankYou = () => {
    return (
      <div className="centerH marginBot30">
        <div className="sectionTitle3">Thank You!</div>
        <div>
          Thank you for contacting us! Someone will get back to you within 24
          hours.
        </div>
        <div style={{ margin: "20px auto 0 auto", textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.resetContactForm()
            }}
            className="primaryBtn"
            style={{ margin: "0 auto 0 auto" }}
          >
            Ok
          </Button>
        </div>
      </div>
    )
  }

  manageRender = () => {
    if (this.props.contact.messageSent) {
      return this.renderThankYou()
    } else {
      return this.renderContactForm()
    }
  }

  render = () => (
    <div
      className="centerChildren"
      style={{
        padding: "60px 40px 60px 40px",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <div className="sectionTitle color1 centerH">Let's Chat</div>
      <div className="sectionSubTitle centerH">We Are Here To Help You</div>
      {this.manageRender()}
    </div>
  )
}
