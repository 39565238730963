import React, { Component } from "react"
import Logo from "./Logo"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBars as fasBars,
  faTimes as fasTimes
} from "@fortawesome/fontawesome-free-solid"
// import { faStar as farStar } from "@fortawesome/fontawesome-free-regular"

export default class Header extends Component {
  renderMainNavBtn = () => {
    if (this.props.navMenu.paneOpen) {
      return (
        <div
          className="mainNavButton"
          onClick={() => {
            this.props.navMenuActions.setNavMenuPaneOpen(false)
          }}
        >
          <FontAwesomeIcon icon={fasTimes} size="3x" className="color1" />
        </div>
      )
    } else {
      return (
        <div
          className="mainNavButton"
          onClick={() => {
            this.props.navMenuActions.setNavMenuPaneOpen(true)
          }}
        >
          <FontAwesomeIcon icon={fasBars} size="3x" className="color1" />
        </div>
      )
    }
  }

  render = () => (
    <div id="header">
      <div style={{ height: "8px", width: "100%", backgroundColor: "#000" }} />
      <div id="header-content">
        <div className="header-left">
          <div
            style={{
              display: "flex",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px"
            }}
          >
            <Logo />
          </div>
        </div>
        <div className="header-right">
          <div>&nbsp;</div>

          {this.renderMainNavBtn()}
        </div>
      </div>
    </div>
  )
}
