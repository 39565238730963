import React, { Component } from "react"
import LogoFooter from "./LogoFooter"

export default class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = { copyYear: "2019" }
  }

  componentDidMount() {
    const d = new Date()
    let year = d.getFullYear()
    if (typeof year === "undefined") {
      year = 2019
    }
    this.setState({ copyYear: year })
  }

  render = () => (
    <div className="footer">
      <div className="footerLinks">
        <a id="homeFooter" className="footerMainLink" href="#home">
          <div>HOME</div>
        </a>
        <a id="whoFooter" className="footerMainLink" href="#who">
          <div>WHO</div>
        </a>
        <a id="workFooter" className="footerMainLink" href="#work">
          <div>WORK</div>
        </a>
        <a id="contactFooter" className="footerMainLink" href="#contact">
          <div>CONTACT</div>
        </a>
      </div>
      <div style={{ flexDirection: "column" }}>
        <LogoFooter />
      </div>
      <div className="copyrightBar">
        COPYRIGHT &copy; 2002 - {this.state.copyYear} CYWISE, LLC. ALL RIGHTS
        RESERVED.
      </div>
    </div>
  )
}
