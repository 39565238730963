import {
  CONTACT_SET_MESSAGE_SENT,
  CONTACT_SET_PANE_OPEN,
  CONTACT_SET_ERROR_MESSAGE
} from "../constants/constants"

const initialState = {
  errorMessage: "",
  paneOpen: false,
  messageSent: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CONTACT_SET_MESSAGE_SENT: {
      return { ...state, messageSent: action.payload }
    }

    case CONTACT_SET_ERROR_MESSAGE: {
      return { ...state, errorMessage: action.payload }
    }

    case CONTACT_SET_PANE_OPEN: {
      return { ...state, paneOpen: action.payload }
    }

    default: {
      return state
    }
  }
}
