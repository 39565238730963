import React, { Component } from "react"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { library } from "../../utils/validators"
import { sendCareerForm } from "../../webApi/awsApi"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      first: "",
      firstError: "",
      last: "",
      lastError: "",
      email: "",
      emailError: "",
      linkedIn: "",
      linkedInError: "",
      hear: "",
      hearError: "",
      message: "",
      messageError: ""
    }
  }

  validate = () => {
    let { first, last, email, linkedIn, hear, message } = this.state

    let errorCount = 0

    if (library.isBlank(first)) {
      this.setState({ firstError: "This field is required." })
      errorCount = errorCount + 1
    } else {
      this.setState({ firstError: "" })
    }

    if (library.isBlank(last)) {
      this.setState({ lastError: "This field is required." })
      errorCount = errorCount + 1
    } else {
      this.setState({ lastError: "" })
    }

    if (library.isBlank(email)) {
      this.setState({ emailError: "This field is required." })
      errorCount = errorCount + 1
    } else {
      this.setState({ emailError: "" })

      if (!library.isEmail(email)) {
        this.setState({ emailError: "Please enter a valid email address." })
        errorCount = errorCount + 1
      } else {
        this.setState({ emailError: "" })
      }
    }

    if (library.isBlank(linkedIn)) {
      this.setState({ linkedInError: "This field is required." })
      errorCount = errorCount + 1
    } else {
      this.setState({ linkedInError: "" })
    }

    if (library.isBlank(hear)) {
      this.setState({ hearError: "This field is required." })
      errorCount = errorCount + 1
    } else {
      this.setState({ hearError: "" })
    }

    if (library.isBlank(message)) {
      this.setState({ messageError: "This field is required." })
      errorCount = errorCount + 1
    } else {
      this.setState({ messageError: "" })
    }

    return errorCount
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    })
  }

  handleContactForm = () => {
    let hasErrors = this.validate()
    if (hasErrors) {
      return
    }

    const { first, last, email, linkedIn, hear, message } = this.state

    const data = {
      first,
      last,
      email,
      linkedIn,
      hear,
      message,
      role: this.props.careers.selectedOption
    }

    sendCareerForm(data) //api
    this.setState({ sent: true })
  }

  renderCareerForm = () => {
    return (
      <div
        className="centerChildren"
        style={{
          padding: "0px 40px 60px 40px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="hide sectionTitle color1 centerH">Let's Chat</div>
        <div className="hide sectionSubTitle centerH">
          We Are Here To Help You
        </div>
        <div className="centerH marginBot30">
          Please fill out the form below...
        </div>
        <div
          className="contactFormHolder"
          style={{ marginLeft: "auto", marginRight: "auto" }}
        >
          <form noValidate autoComplete="off">
            <div>
              <TextField
                id="contact-first"
                required
                fullWidth
                label="First Name"
                // className={classes.textField}
                value={this.state.first}
                onChange={this.handleChange("first")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.firstError}</div>
            </div>

            <div>
              <TextField
                id="contact-last"
                required
                fullWidth
                label="Last Name"
                // className={classes.textField}
                value={this.state.last}
                onChange={this.handleChange("last")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.lastError}</div>
            </div>

            <div>
              <TextField
                id="contact-email"
                required
                fullWidth
                label="Email Address"
                // className={classes.textField}
                value={this.state.email}
                onChange={this.handleChange("email")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.emailError}</div>
            </div>

            <div>
              <TextField
                id="contact-linkedIn"
                required
                fullWidth
                label="LinkedIn (or your personal website)"
                // className={classes.textField}
                value={this.state.linkedIn}
                onChange={this.handleChange("linkedIn")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.linkedInError}</div>
            </div>

            <div>
              <TextField
                id="contact-hear"
                required
                fullWidth
                label="How did you hear about us?"
                // className={classes.textField}
                value={this.state.hear}
                onChange={this.handleChange("hear")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.hearError}</div>
            </div>

            <div>
              <TextField
                id="contact-message"
                required
                label="Message"
                // className={classes.textField}
                value={this.state.message}
                multiline
                fullWidth
                rows="4"
                rowsMax="4"
                onChange={this.handleChange("message")}
                margin="normal"
                variant="filled"
              />
              <div className="errorText">{this.state.messageError}</div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <div style={{ margin: "20px auto 0 auto", textAlign: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    this.handleContactForm()
                  }}
                  className="primaryBtn"
                  style={{ margin: "0 auto 0 auto" }}
                >
                  SEND
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }

  renderConfirmSent = () => {
    return (
      <div
        className="centerChildren"
        style={{
          padding: "0px 40px 60px 40px",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div className="hide sectionTitle color1 centerH">Thank You</div>
        <div className="hide sectionSubTitle centerH">
          We Are Here To Help You
        </div>
        <div className="centerH marginBot30" style={{ textAlign: "left" }}>
          Thank you for contacting us about this opportunity! Someone will reach
          out to you once we have more information abut the role from the
          client. In the event you don't hear back from us within 2 weeks, the
          role has either has not materialized, been filled or put on hold.
          Regardless, again we thank you and please check back for future
          opportunities.
        </div>
        <div style={{ marginTop: "20px" }}>
          <div style={{ margin: "20px auto 0 auto", textAlign: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                this.props.history.push("/")
              }}
              className="primaryBtn"
              style={{ margin: "0 auto 0 auto" }}
            >
              Home
            </Button>
          </div>
        </div>
      </div>
    )
  }

  manageRender = () => {
    if (this.state.sent) {
      return this.renderConfirmSent()
    } else {
      return this.renderCareerForm()
    }
  }

  render = () => <div>{this.manageRender()}</div>
}
