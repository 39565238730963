import React, { Component } from "react"

export default class OurWork extends Component {
  render = () => (
    <div
      className="workBg"
      style={{
        padding: "20px 40px 20px 40px"
      }}
    >
      <div className="sectionBg">
        <div className="sectionTitle color1">Our Work</div>
        <div className="sectionSubTitle">
          If You Can Dream It, We Can Build It
        </div>
        <div className="workText">
          <span>
            Whether as a contractor, sub-contractor, team or individual, Cywise
            is here to help. Our team has extensive experience in designing and
            developing a variety of digital works for orgaizations of all sizes.
            Its about applying our passion to help others meet their objectives.
          </span>
        </div>
      </div>
    </div>
  )
}
