import React, { Component } from "react"
import Modal from "react-modal"
import Header from "../components/shared/Header"
import Home from "../components/Home"
import ContactForm from "../components/contactForm/ContactForm"
import Ideas from "../components/shared/Ideas"
import WhoWeAre from "../components/WhoWeAre"
import OurWork from "../components/OurWork"
import Portfolio from "../components/Portfolio"
import ServicesBox from "../components/shared/ServicesBox"
// import FillNeeds from "../components/shared/FillNeeds"
// import ContactUsForm from "../components/shared/ContactUsForm"
import Footer from "../components/shared/Footer"
import SlidingPane from "react-sliding-pane"
import "react-sliding-pane/dist/react-sliding-pane.css"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

// ACTIONS
import * as careerActions from "../actions/careerActions"
import * as contactActions from "../actions/contactActions"
import * as navMenuActions from "../actions/navMenuActions"
import * as sharedActions from "../actions/sharedActions"

import ScrollableAnchor from "react-scrollable-anchor"
import Button from "@material-ui/core/Button"
import Snackbar from "@material-ui/core/Snackbar"

// Modal.setAppElement('#yourAppElement')

class HomeContainer extends Component {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     isPaneOpenTop: true
  //   }
  // }

  componentDidMount() {
    Modal.setAppElement("#root")
  }

  handleSnackBarClose = () => {
    this.props.sharedActions.setSnackBarOpen(false)
  }

  render = () => (
    <div>
      <Header {...this.props} />

      <ScrollableAnchor id={"home"}>
        <Home {...this.props} />
      </ScrollableAnchor>

      <ServicesBox {...this.props} />

      <ScrollableAnchor id={"who"}>
        <WhoWeAre {...this.props} />
      </ScrollableAnchor>

      {/* OUR WORK */}
      <ScrollableAnchor id={"work"}>
        <OurWork {...this.props} />
      </ScrollableAnchor>

      <Portfolio {...this.props} />

      {/* newsletter */}
      <Ideas {...this.props} />

      {/* <FillNeeds {...this.props} /> */}
      <ScrollableAnchor id={"contact"}>
        <ContactForm {...this.props} />
      </ScrollableAnchor>

      <Footer {...this.props} />

      <SlidingPane
        id="navMenu"
        isOpen={this.props.navMenu.paneOpen}
        title="Hey, it is optional pane title.  I can be React component too."
        from="right"
        width="80%"
        onRequestClose={() => this.setState({ isPaneOpenTop: false })}
        ariaHideApp={false}
        overlayClassName="mainNavOverlay"
        className="mainNavOverlay"
      >
        <div>
          <a
            id="navMenu_home"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#home"
          >
            <div className="menuItemText">HOME</div>
          </a>
        </div>

        <div>
          <a
            id="navMenu_who"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#who"
          >
            <div className="menuItemText">WHO</div>
          </a>
        </div>

        <div>
          <a
            id="navMenu_work"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#work"
          >
            <div className="menuItemText">WORK</div>
          </a>
        </div>

        <div>
          <a
            id="navMenu_contact"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#contact"
          >
            <div className="menuItemText">CONTACT</div>
          </a>
        </div>

        <div style={{ display: "none" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.handleContactForm()
            }}
            className="primaryBtn"
          >
            JOIN OUR TEAM
          </Button>
        </div>
      </SlidingPane>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={this.props.shared.snackBarOpen}
        autoHideDuration={6000}
        onClose={this.handleSnackBarClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{this.props.contact.errorMessage}</span>}
      />
    </div>
  )
}

export default connect(
  ({ career, contact, navMenu, shared }) => ({
    career,
    contact,
    navMenu,
    shared
  }),
  dispatch => ({
    careerActions: bindActionCreators(careerActions, dispatch),
    contactActions: bindActionCreators(contactActions, dispatch),
    navMenuActions: bindActionCreators(navMenuActions, dispatch),
    sharedActions: bindActionCreators(sharedActions, dispatch)
  })
)(HomeContainer)
