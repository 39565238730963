import React, { Component } from "react"
import { Link } from "react-router-dom"

export default class Logo extends Component {
  render = () => (
    <Link to="/">
      <img
        alt="Cywise"
        src={require("../../css/themes/cywise/images/CywiseLogoIcon.png")}
        style={{ height: "40px" }}
      />
    </Link>
  )
}
