import React, { Component } from "react"

export default class Home extends Component {
  render = () => (
    <div className="page">
      <div className="banner">
        <div className="worldDotMap" />
        <div className="banner-headerText">
          <div className="color1" style={{ fontSize: "40px" }}>
            Cywise is a Web Design & Development Firm
          </div>
          <div
            className="color4"
            style={{ fontSize: "16px", fontWeight: "bold", marginTop: "10px" }}
          >
            <div className="homeLine1" style={{ marginBottom: "10px" }}>
              Your team of designers, developers and engineers are here.
            </div>
            <div className="homeLine2" style={{ marginBottom: "10px" }}>
              We have been creating amazing experiences for over 15 years.
            </div>
            <div className="homeLine3" style={{ marginBottom: "10px" }}>
              We're passionate about what we do and can't wait to meet you!
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
