import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faCircle as fasPenNib } from "@fortawesome/fontawesome-free-solid"
import { faCircle as farCircle } from "@fortawesome/fontawesome-free-regular"

// import { faBrowser } from "@fortawesome/fontawesome-free-regular"
import {
  faPenNib as fasPenNib,
  faCode as fasCode
} from "@fortawesome/free-solid-svg-icons"
import { faHandshake as farHandshake } from "@fortawesome/free-regular-svg-icons"

export default class ServicesBox extends Component {
  render = () => (
    <div style={{ backgroundColor: "#eee", padding: "20px" }}>
      <div className="cardBoxHolder">
        <div className="cardBox cardBox1">
          <div className="cardBoxHead fill2">
            <span className="fa-stack fa-2x">
              <FontAwesomeIcon icon={farCircle} className="fa-stack-2x" />
              <FontAwesomeIcon icon={fasPenNib} className="fa-stack-1x" />
            </span>
          </div>
          <div className="cardBoxInfo">
            <div className="cardBoxTitle">Design</div>
            <div className="cardBoxText">
              User Experience & Interface Design: We create beautiful
              experiences through user-focused, goal-oriented design. Great
              products and services starts here.
            </div>
          </div>
        </div>
        <div className="cardBox cardBox2">
          <div className="cardBoxHead fill3">
            <span className="fa-stack fa-2x">
              <FontAwesomeIcon icon={farCircle} className="fa-stack-2x" />
              <FontAwesomeIcon icon={fasCode} className="fa-stack-1x" />
            </span>
          </div>
          <div className="cardBoxInfo">
            <div className="cardBoxTitle">Development</div>
            <div className="cardBoxText">
              Since 2002, companies have turned to Cywise to help them produce
              amazing web and mobile experiences or scale their development
              teams.
            </div>
          </div>
        </div>
        <div className="cardBox  cardBox3">
          <div className="cardBoxHead fill1-a">
            <span className="fa-stack fa-2x">
              <FontAwesomeIcon icon={farCircle} className="fa-stack-2x" />
              <FontAwesomeIcon icon={farHandshake} className="fa-stack-1x" />
            </span>
          </div>
          <div className="cardBoxInfo">
            <div className="cardBoxTitle">Marketing</div>
            <div className="cardBoxText">
              Raise awareness and be found by your target audience through
              campaigns, social media, content creation, ads and more.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
