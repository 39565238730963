import { CAREERS_SET_SELECTED } from "../constants/constants"

const initialState = {
  selectedOption: ""
}

export default function(state = initialState, action) {
  switch (action.type) {
    case CAREERS_SET_SELECTED: {
      return { ...state, selectedOption: action.payload }
    }

    default: {
      return state
    }
  }
}
