import {
  SHARED_SET_NEWSLETTER_SUBMITTED,
  SHARED_SET_SNACKBAR_OPEN
} from "../constants/constants"

const initialState = {
  newsletterSubmitted: false,
  snackBarOpen: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SHARED_SET_NEWSLETTER_SUBMITTED: {
      return { ...state, newsletterSubmitted: action.payload }
    }

    case SHARED_SET_SNACKBAR_OPEN: {
      return { ...state, snackBarOpen: action.payload }
    }

    default: {
      return state
    }
  }
}
