import {
  SHARED_SET_NEWSLETTER_SUBMITTED,
  SHARED_SET_SNACKBAR_OPEN
} from "../constants/constants"

export const setNewsletterSubmitted = payload => {
  return {
    type: SHARED_SET_NEWSLETTER_SUBMITTED,
    payload
  }
}

export const setSnackBarOpen = payload => {
  return {
    type: SHARED_SET_SNACKBAR_OPEN,
    payload
  }
}
