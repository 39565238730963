import React, { Component } from "react"

export default class LogoFooter extends Component {
  render = () => (
    <img
      alt="Cywise"
      src={require("../../css/themes/cywise/images/CywiseLogoWhite.png")}
      style={{ height: "30px" }}
    />
  )
}
