import axios from "axios"
import * as configs from "../utils/configs"

export const sendContactForm = data => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded"
  axios.post(configs.contactFormUrl, data).then(response => {
    if (response.data.success) {
      const results = response.data.results
      return results
    }
  })
}

export const sendCareerForm = data => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded"
  axios.post(configs.careersFormUrl, data).then(response => {
    if (response.data.success) {
      const results = response.data.results
      return results
    }
  })
}

export const sendNewsletterForm = data => {
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded"
  axios.post(configs.newsletterFormUrl, data).then(response => {
    if (response.data.success) {
      const results = response.data.results
      return results
    }
  })
}
