//configs.js
//export const appUrl = 'http://localhost:8080';
const devMode = "prod" // <---- set to local or server

export let appApiUrl = ""
export let contactFormUrl = ""
export let careersFormUrl = ""
export let newsletterFormUrl =
  "https://p8bsxoxzya.execute-api.us-east-1.amazonaws.com/prod/email/send"

if (devMode === "local") {
  appApiUrl = "http://localhost:4000"
  contactFormUrl =
    "https://6i1fce2mc4.execute-api.us-east-1.amazonaws.com/dev/email/send"
  careersFormUrl =
    "https://vaihypf5hd.execute-api.us-east-1.amazonaws.com/prod/email/send"
  newsletterFormUrl =
    "https://p8bsxoxzya.execute-api.us-east-1.amazonaws.com/prod/email/send"
} else if (devMode === "stage") {
  appApiUrl = "http://52.201.116.92:4000"
  contactFormUrl =
    "https://6i1fce2mc4.execute-api.us-east-1.amazonaws.com/dev/email/send"
  careersFormUrl =
    "https://vaihypf5hd.execute-api.us-east-1.amazonaws.com/prod/email/send"
  newsletterFormUrl =
    "https://p8bsxoxzya.execute-api.us-east-1.amazonaws.com/prod/email/send"
} else if (devMode === "prod") {
  appApiUrl = "http://localhost:4000"
  contactFormUrl =
    "https://mkfb7qvtsb.execute-api.us-east-1.amazonaws.com/prod/email/send"
  careersFormUrl =
    "https://vaihypf5hd.execute-api.us-east-1.amazonaws.com/prod/email/send"
  newsletterFormUrl =
    "https://p8bsxoxzya.execute-api.us-east-1.amazonaws.com/prod/email/send"
}
