import {
  CONTACT_SET_ERROR_MESSAGE,
  CONTACT_SET_MESSAGE_SENT,
  CONTACT_SET_PANE_OPEN
} from "../constants/constants"

export const setContactPaneOpen = payload => {
  return {
    type: CONTACT_SET_PANE_OPEN,
    payload
  }
}

export const setErrorMessage = payload => {
  return {
    type: CONTACT_SET_ERROR_MESSAGE,
    payload
  }
}

export const setMessageSent = payload => {
  return {
    type: CONTACT_SET_MESSAGE_SENT,
    payload
  }
}
