import React, { Component } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight as farAngleRight } from "@fortawesome/free-solid-svg-icons"

export default class CareerOption extends Component {
  manageSelected = () => {
    this.props.careerActions.setSelected(this.props.jobRole)
  }

  render = () => (
    <div
      className={
        this.props.topRow
          ? "jobsCategoryOption jobsCategoryOptionTop"
          : "jobsCategoryOption"
      }
      onClick={() => {
        this.manageSelected()
      }}
    >
      <div className="jobsCategoryLink">{this.props.jobRole}</div>
      <div className="jobsCategoryLinkArrow">
        <FontAwesomeIcon icon={farAngleRight} />
      </div>
    </div>
  )
}
