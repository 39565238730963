import { combineReducers } from "redux"
import careers from "./careersReducer"
import contact from "./contactReducer"
import shared from "./sharedReducer"
import navMenu from "./navMenuReducer"
import user from "./userReducer"

const reducers = {
  careers,
  contact,
  navMenu,
  shared,
  user
}

export default combineReducers(reducers)
