import React, { Component } from "react"
import { withRouter, Switch, Route } from "react-router-dom"
import "./css/themes/cywise/theme.css"
//import ReactGA from "react-ga"
import HomeContainer from "./containers/HomeContainer"
import CareersContainer from "./containers/CareersContainer"

class App extends Component {

  render() {
    return (
      <div className="App">
        <div className="AppContent" role="main">
          <Switch>
            <Route exact path="/" component={HomeContainer} />
            <Route path="/careers" component={CareersContainer} />
          </Switch>
        </div>
      </div>
    )
  }
}

export default withRouter(App)
