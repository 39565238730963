import React, { Component } from "react"
import Header from "../components/shared/Header"
import Careers from "../components/careers/Careers"
import Footer from "../components/shared/Footer"
import SlidingPane from "react-sliding-pane"
import "react-sliding-pane/dist/react-sliding-pane.css"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import * as navMenuActions from "../actions/navMenuActions"
import * as careerActions from "../actions/careerActions"

class CareersContainer extends Component {
  render = () => (
    <div>
      <Header {...this.props} />
      <Careers {...this.props} />
      <Footer {...this.props} />

      <SlidingPane
        id="navMenu"
        isOpen={this.props.navMenu.paneOpen}
        title="Hey, it is optional pane title.  I can be React component too."
        from="right"
        width="80%"
        onRequestClose={() => this.setState({ isPaneOpenTop: false })}
        ariaHideApp={false}
        overlayClassName="mainNavOverlay"
        className="mainNavOverlay"
      >
        <div>
          <a
            id="navMenu_home"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#home"
          >
            <div className="menuItemText">HOME</div>
          </a>
        </div>

        <div>
          <a
            id="navMenu_who"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#who"
          >
            <div className="menuItemText">WHO</div>
          </a>
        </div>

        <div>
          <a
            id="navMenu_work"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#work"
          >
            <div className="menuItemText">WORK</div>
          </a>
        </div>

        <div>
          <a
            id="navMenu_contact"
            className="menuItem"
            onClick={() => {
              this.props.navMenuActions.setNavMenuPaneOpen(false)
            }}
            href="#contact"
          >
            <div className="menuItemText">CONTACT</div>
          </a>
        </div>
      </SlidingPane>
    </div>
  )
}

export default connect(
  ({ careers, navMenu }) => ({ careers, navMenu }),
  dispatch => ({
    careerActions: bindActionCreators(careerActions, dispatch),
    navMenuActions: bindActionCreators(navMenuActions, dispatch)
  })
)(CareersContainer)
