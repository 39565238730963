export const ssoHasRequired = data => {
  let need = []
  if (!library.hasValue(data.email)) {
    need.push("email")
  }

  if (!library.hasValue(data.dateOfBirth)) {
    need.push("dateOfBirth")
  }

  return need
}

/* ex. fileName = 'myFile.mp3', allowedTypesArray = ['mp3, m4a'] */
export const isValidFileType = (fileName, allowedTypesArray) => {
  if (fileName.indexOf(".") > 0) {
    const parts = fileName.split(".")
    const ext = parts[parts.length - 1]
    let fileTypeCheck = allowedTypesArray.filter(ftype => {
      return ftype.toLowerCase() === ext.toLowerCase()
    })

    return !fileTypeCheck.length ? false : true
  }
}

/*
// already exists in library
export const hasValue = val => {
  return val === "" || val === undefined || val === null ? false : true
}
*/

//Validation Utility Library Obj
export let library = {
  hasValue: val => {
    return val === "" || val === undefined || val === null ? false : true
  },
  calcAge: function(birthday) {
    // date of birth
    var ageDifMs = Date.now() - new Date(birthday).getTime()
    var ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  },
  email: function(value) {
    return library.regex(value, /\S+@\S+\.\S+/)
  },
  emailDot: function(value) {
    return library.required(value) && value.indexOf(".") > -1
  },
  emailBadDot: function(value) {
    const parts = value.split("@")
    return (
      parts[0].charAt(0) !== "." && parts[0].substr(parts[0].length - 1) !== "."
    )
  },
  isEmail: function(value) {
    return (
      library.email(value) &&
      library.emailDot(value) &&
      library.emailBadDot(value)
    )
  },
  username: function(value) {
    return library.regex(value, /^[0-9A-Za-z\s_-]+$/)
  },
  equals: function(value, baseline) {
    return value === baseline
  },
  isBlank: function(value) {
    return value === undefined || value === null || value === ""
  },
  exists: function(value) {
    return value !== undefined && value !== null
  },
  greaterThan: function(value, baseline) {
    return library.required(value) && Number(value) > baseline
  },
  integer: function(value) {
    return library.regex(value, /^-?[0-9]+$/)
  },
  length: function(value, len) {
    return (
      library.exists(value) &&
      library.exists(value.length) &&
      value.length === len
    )
  },
  lessThan: function(value, baseline) {
    return library.required(value) && Number(value) < baseline
  },
  max: function(value, baseline) {
    return library.required(value) && Number(value) <= baseline
  },
  maxLength: function(value, len) {
    return (
      library.exists(value) &&
      library.exists(value.length) &&
      value.length <= len
    )
  },
  min: function(value, baseline) {
    return library.required(value) && Number(value) >= baseline
  },
  minLength: function(value, len) {
    return (
      library.exists(value) &&
      library.exists(value.length) &&
      value.length >= len
    )
  },
  number: function(value) {
    return library.required(value) && !Number.isNaN(Number(value))
  },
  numeric: function(value) {
    return library.regex(value, /^[0-9]+$/)
  },
  regex: function(value, pattern) {
    return library.required(value) && pattern.test(value)
  },
  validZipMask: function(postalCode) {
    if (postalCode.indexOf("_")) {
      return false
    }
    if (postalCode.length < 5 || !library.checkZipMask(postalCode)) {
      return false
    }

    return true
  },
  removeZipMask: function(postalCode) {
    postalCode = postalCode.replace(/_/g, "")
    const lastChar = postalCode.substr(postalCode.length - 1)
    if (lastChar === "-") {
      postalCode = postalCode.replace("-", "")
    }

    return postalCode
  },
  checkZipMask: function(postalCode) {
    return postalCode.charAt(0) !== "-"
  },
  required: function(value) {
    return typeof value === "string" && value.trim() !== ""
  },
  startsWith: function(value, searchString) {
    return (
      library.required(value) &&
      library.exists(searchString) &&
      value.substr(0, searchString.length) === searchString
    )
  },
  url: function(value) {
    // matches blank strings so you have a choice of pairing it with required
    if (value === null || (typeof value === "string" && value.trim() === "")) {
      return true
    }
    //
    // https://gist.github.com/dperini/729294
    //
    let re_weburl = new RegExp(
      "^" +
        // protocol identifier
        "(?:(?:https?|ftp)://)" +
        // user:pass authentication
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        // IP address exclusion
        // private & local networks
        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
        // IP address dotted notation octets
        // excludes loopback network 0.0.0.0
        // excludes reserved space >= 224.0.0.0
        // excludes network & broacast addresses
        // (first & last IP address of each class)
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
        "|" +
        // host name
        "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
        // domain name
        "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
        // TLD identifier
        "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
        // TLD may end with dot
        "\\.?" +
        ")" +
        // port number
        "(?::\\d{2,5})?" +
        // resource path
        "(?:[/?#]\\S*)?" +
        "$",
      "i"
    )
    return library.regex(value, re_weburl)
  }
}
