import React, { Component } from "react"

export default class Portfolio extends Component {
  render = () => (
    <div style={{ backgroundColor: "#fff", padding: "60px 0 0 0" }}>
      <div className="sectionTitle2 color1 centerH">
        You're in good company.
      </div>
      <div
        className="sectionText"
        style={{ width: "80%", margin: "0 auto 40px auto" }}
      >
        <span className="portSelectedList">
          Over the years the talented individuals that make up the Cywise team
          has had the privilege of serving hundreds of people and scores of
          organizations (a select list follows).
        </span>
        <span className="portSelectedIndustry">
          Over the years the talented individuals that make up the Cywise team
          has had the privilege of serving hundreds of people and scores of
          organizations in the following industries.
        </span>
      </div>

      <div className="listBoxHolder">
        <div className="listBox">
          <div className="listBoxInfo">
            <div className="listItem">
              <div className="listTitle">Aerospace</div>
              <div className="listTextBox">
                <div className="listText">Pratt & Whitney</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Automotive</div>
              <div className="listTextBox">
                <div className="listText">Mercedes-Benz</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Consumer & Retail</div>
              <div className="listTextBox">
                <div className="listText">Crabtree & Evelyn</div>
                <div className="listText">Ralph Lauren</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Engineering</div>
              <div className="listTextBox">
                <div className="listText">Belcan</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Financial Services</div>
              <div className="listTextBox">
                <div className="listText">Atlantic Information Services</div>
                <div className="listText">Bank of America</div>
                <div className="listText">Citigroup</div>
                <div className="listText">Commonfund</div>
                <div className="listText">Flag Capital</div>
                <div className="listText">Phoenx Wealth Management</div>
                <div className="listText">The Hartford</div>
              </div>
            </div>
          </div>
        </div>
        <div className="listBox">
          <div className="listBoxInfo">
            <div className="listItem">
              <div className="listTitle">Government</div>
              <div className="listTextBox">
                <div className="listText">State of Connecticut</div>
                <div className="listText">
                  State & Federally funded projects
                </div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Internet & E-Business</div>
              <div className="listTextBox">
                <div className="listText">Freshnex</div>
                <div className="listText">Network Security</div>
                <div className="listText">Tweedl</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Insurance Services</div>
              <div className="listTextBox">
                <div className="listText">Aetna</div>
                <div className="listText">Cigna</div>
                <div className="listText">Selective Insurance</div>
                <div className="listText">The Hartford</div>
                <div className="listText">Travelers</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Manufacturing & Industrial</div>
              <div className="listTextBox">
                <div className="listText">Rostra Vernatherm</div>
              </div>
            </div>
          </div>
        </div>
        <div className="listBox">
          <div className="listBoxInfo">
            <div className="listItem">
              <div className="listTitle">Media & Publishing</div>
              <div className="listTextBox">
                <div className="listText">Macmillan Publishers</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Pharmaceutical</div>
              <div className="listTextBox">
                <div className="listText">Bayer</div>
                <div className="listText">Johnson & Johnson</div>
              </div>
            </div>

            <div className="listItem">
              <div className="listTitle">Sports & Entertainment</div>
              <div className="listTextBox">
                <div className="listText">ESPN</div>
              </div>
            </div>
            <div className="listItem">
              <div className="listTitle">Technology</div>
              <div className="listTextBox">
                <div className="listText">Cisco Systems</div>
                <div className="listText">Diamond</div>
                <div className="listText">IBM</div>
                <div className="listText">IniTech</div>
                <div className="listText">Neuvis</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
