import { NAVMENU_SET_PANE_OPEN } from "../constants/constants"

const initialState = {
  paneOpen: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case NAVMENU_SET_PANE_OPEN: {
      return { ...state, paneOpen: action.payload }
    }

    default: {
      return state
    }
  }
}
